import React from 'react'
import project2 from '../../assets/images/projects/project2.jpg'
import { Link } from 'gatsby'

const ServiceDetailsContentProfessionalDevelopment = () => {
    return (
        <section className="services-details-area pb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="services-details-desc services-details-desc-top">
                            <h3>About this service</h3>
                            <p>Spreadsheets are capable of a plethora of tasks of which you and your staff might not be aware. Smarter Spreadsheets founder and teacher, Mark Frye, will deliver professional development to increase awareness of what spreadsheets can do and some simple tricks that you can start using right away to save time.</p>
                            <p>Pricing for professional development sessions start at $200/hr.</p>
                            <ul>
                                <li><span>Sessions appropriate for whole staff PD or smaller groups</span></li>
                                <li><span>Ideal time frame is a 90 minute session for whole staff</span></li>
                                <li><span>Differentiation available for staff with experience with data management</span></li>
                            </ul>
                            <div className="services-details-image">
                                <img src={project2} alt="about" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <div className="services-details-info">
                            <ul className="services-list">
                                <li>
                                    <Link to="/contact">
                                        Contact us to schedule a professional development that meets your needs. 
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceDetailsContentProfessionalDevelopment

import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import ServiceDetailsContentProfessionalDevelopment from '../components/ServiceDetails/ServiceDetailsContentProfessionalDevelopment'
 
const Details = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Professional Development" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="Professional Development" 
            />
            <ServiceDetailsContentProfessionalDevelopment />
            <Footer />
        </Layout>
    );
}

export default Details